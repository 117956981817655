<template>
  <div>
    <v-text-field
      v-show="!swapMode"
      v-model="$v.formData.name.$model"
      class="required-field"
      :disabled="disabled"
      :label="$t('forms.fieldName')"
      :error-messages="errors.name"
      required
      @blur="$v.formData.name.$touch()"/>

    <v-select
      v-model="$v.formData.type.$model"
      :disabled="disabled || swapMode"
      :label="$t('forms.type')"
      :error-messages="errors.type"
      :hint="hints.raw"
      :items="credentialItems"
      :menu-props="{ maxHeight: 521 }"
      item-value="key"
      item-text="displayName"
      required
      class="required-field credentials-list"
      @blur="$v.formData.type.$touch()">
      <template #selection="data">
        <v-avatar
          :key="data.item.key"
          class="mr-2"
          size="24"
          :rounded="false">
          <CyIconCredential
            :colour-image="!swapMode"
            :colour-icon="!swapMode"
            :type="data.item.key"
            disabled/>
        </v-avatar>
        <div class="black--text">
          {{ data.item.displayName }}
        </div>
      </template>
      <template #item="data">
        <div class="credentials-list__item">
          <v-list-item-avatar>
            <CyIconCredential
              large
              :type="data.item.key"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.displayName }}</v-list-item-title>
          </v-list-item-content>
        </div>
      </template>
    </v-select>

    <v-text-field
      v-model="$v.formData.path.$model"
      :hint="hints.path"
      :error-messages="errors.path"
      :readonly="!isPathCustom"
      :disabled="disabled || !isPathCustom"
      :placeholder="generatedPath"
      persistent-hint
      required
      class="required-field credential__path"
      @blur="$v.formData.path.$touch()">
      <v-icon
        v-if="!swapMode"
        slot="append-outer"
        color="darkgrey"
        @click="setIsPathCustom(!isPathCustom)">
        {{ isPathCustom ? 'lock_open' : 'lock' }}
      </v-icon>
      <span slot="label">{{ pathLabel }}</span>
      <CyCopyBtn
        v-if="formData.path"
        slot="append"
        :copy-value="formData.path"/>
    </v-text-field>

    <div
      v-if="!!_.$get(formData, 'type') && !hideDetails"
      v-has-rights-to="'GetCredential'"
      class="credential__raw-container">
      <template v-if="typeIs.aws">
        <v-text-field
          v-model="$v.formData.raw.access_key.$model"
          :append-icon="hideAsPassword.aws.accessKey ? 'visibility' : 'visibility_off'"
          :type="hideAsPassword.aws.accessKey ? 'password' : 'text'"
          :disabled="disabled || swapMode"
          :error-messages="errors.raw.access_key"
          required
          class="required-field"
          @click:append="hideAsPassword.aws.accessKey = !hideAsPassword.aws.accessKey"
          @blur="$v.formData.raw.access_key.$touch()">
          <template slot="label">
            <span>access_key</span>
            <CyCopyBtn
              v-if="formData.path"
              :copy-hint="`${formData.path}.access_key`"
              :copy-value="`${formData.path}.access_key`"/>
          </template>
        </v-text-field>
        <v-text-field
          v-model="$v.formData.raw.secret_key.$model"
          :disabled="disabled || swapMode"
          :append-icon="hideAsPassword.aws.secretKey ? 'visibility' : 'visibility_off'"
          :type="hideAsPassword.aws.secretKey ? 'password' : 'text'"
          :error-messages="errors.raw.secret_key"
          required
          class="required-field"
          @click:append="hideAsPassword.aws.secretKey = !hideAsPassword.aws.secretKey"
          @blur="$v.formData.raw.secret_key.$touch()">
          <template slot="label">
            <span>secret_key</span>
            <CyCopyBtn
              v-if="formData.path"
              :copy-hint="`${formData.path}.secret_key`"
              :copy-value="`${formData.path}.secret_key`"/>
          </template>
        </v-text-field>
      </template>

      <template v-if="typeIs.gcp">
        <v-text-field
          v-model="$v.formData.raw.json_key.$model"
          :disabled="disabled || swapMode"
          :append-icon="hideAsPassword.gcp.jsonKey ? 'visibility' : 'visibility_off'"
          :type="hideAsPassword.gcp.jsonKey ? 'password' : 'text'"
          :error-messages="errors.raw.json_key"
          required
          class="required-field "
          @click:append="hideAsPassword.gcp.jsonKey = !hideAsPassword.gcp.jsonKey"
          @blur="$v.formData.raw.json_key.$touch()">
          <template slot="label">
            <span>json_key</span>
            <CyCopyBtn
              v-if="formData.path"
              :copy-hint="`${formData.path}.json_key`"
              :copy-value="`${formData.path}.json_key`"/>
          </template>
        </v-text-field>
      </template>

      <template v-if="typeIs.azure_storage">
        <v-text-field
          v-model="$v.formData.raw.account_name.$model"
          :disabled="disabled || swapMode"
          :append-icon="hideAsPassword.azureStorage.accountName ? 'visibility' : 'visibility_off'"
          :type="hideAsPassword.azureStorage.accountName ? 'password' : 'text'"
          :error-messages="errors.raw.account_name"
          required
          class="required-field "
          @click:append="hideAsPassword.azureStorage.accountName = !hideAsPassword.azureStorage.accountName"
          @blur="$v.formData.raw.account_name.$touch()">
          <template slot="label">
            <span>account_name</span>
            <CyCopyBtn
              v-if="formData.path"
              :copy-hint="`${formData.path}.account_name`"
              :copy-value="`${formData.path}.account_name`"/>
          </template>
        </v-text-field>
        <v-text-field
          v-model="$v.formData.raw.access_key.$model"
          :disabled="disabled || swapMode"
          :append-icon="hideAsPassword.azureStorage.accessKey ? 'visibility' : 'visibility_off'"
          :type="hideAsPassword.azureStorage.accessKey ? 'password' : 'text'"
          :error-messages="errors.raw.access_key"
          required
          class="required-field "
          @click:append="hideAsPassword.azureStorage.accessKey = !hideAsPassword.azureStorage.accessKey"
          @blur="$v.formData.raw.access_key.$touch()">
          <template slot="label">
            <span>access_key</span>
            <CyCopyBtn
              v-if="formData.path"
              :copy-hint="`${formData.path}.access_key`"
              :copy-value="`${formData.path}.access_key`"/>
          </template>
        </v-text-field>
      </template>
    </div>
  </div>
</template>

<script>
import CyCopyBtn from '@/components/copy-btn'
import { required } from 'vuelidate/lib/validators'
import { checksPass } from '@/utils/helpers'
import { credentialTypesAllowed } from '@/utils/helpers/cloud-cost-management'
import { pathIsValid, CREDENTIAL_DEFAULTS } from '@/utils/helpers/credentials'
import { mapGetters } from 'vuex'

export default {
  name: 'CyCloudCostManagementFormsCredentials',
  components: {
    CyCopyBtn,
  },
  props: {
    credential: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    swapMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    canSave: false,
    formData: {
      name: '',
      type: '',
      path: '',
      raw: undefined,
    },
    isPathCustom: false,
    hideAsPassword: {
      aws: {
        accessKey: true,
        secretKey: true,
      },
      azureStorage: {
        accountName: true,
        accessKey: true,
      },
      gcp: {
        jsonKey: true,
      },
    },
  }),
  validations () {
    return {
      formData: {
        name: {
          required,
        },
        path: {
          validPath: (path) => pathIsValid(path),
          required,
        },
        type: {
          required,
        },
        raw: {
          ...this.rawValidations,
        },
      },
    }
  },
  computed: {
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCredType',
    ]),
    $static: () => ({
      credentialTypesAllowed,
    }),
    credentialItems () {
      return _.chain(this.getProviderExtraInfoByCredType)
        .filter(({ credentialType }) => _.includes(this.$static.credentialTypesAllowed, credentialType))
        .map(({ credentialType, displayName }) => ({
          key: credentialType,
          displayName,
        }))
        .value()
    },
    generatedPath () {
      const { name = '' } = this.formData
      return this.$getSlug(name)
    },
    hints () {
      return {
        path: (() => {
          if (this.swapMode) return ''
          if (this.isPathCustom) return this.$t('credentials.fieldPathHint')
          return this.$t('credentials.fieldPathReadonly')
        })(),
        raw: (() => {
          if (_.isEmpty(this.formData.raw)) return this.$t('credentials.fieldTypeRawHint')
          return ''
        })(),
      }
    },
    errors () {
      return {
        name: (() => {
          const errors = []
          const { $dirty, required } = this.$v.formData.name
          if (!$dirty) return errors
          if (!required) errors.push(this.$t('forms.fieldRequired'))
          return errors
        })(),
        path: (() => {
          const errors = []
          const { $dirty, required, validPath } = this.$v.formData.path
          if (!$dirty) return errors
          if (!required) errors.push(this.$t('forms.fieldRequired'))
          if (required && !validPath) errors.push(this.$t('credentials.pathInvalid'))
          return errors
        })(),
        type: (() => {
          const errors = []
          const { $dirty, required } = this.$v.formData.type
          if (!$dirty) return errors
          if (!required) errors.push(this.$t('forms.fieldRequired'))
          return errors
        })(),
        raw: (() => {
          if (_.$isEmpty(this.formData.type)) return []
          if (this.typeIs.aws) {
            return {
              access_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formData.raw.access_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              secret_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formData.raw.secret_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.gcp) {
            return {
              json_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formData.raw.json_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
          if (this.typeIs.azure_storage) {
            return {
              access_key: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formData.raw.access_key
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
              account_name: (() => {
                const errors = []
                const { $dirty, required } = this.$v.formData.raw.account_name
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }
          }
        })(),
      }
    },
    pathLabel () {
      if (this.hideDetails) return this.$t('untranslated.canonical')
      return this.isPathCustom
        ? this.$t('credentials.customPath')
        : this.$t('forms.fieldPath')
    },
    typeIs () {
      return { [this.formData.type]: true }
    },
    rawValidations () {
      const rawValidations = {
        aws: {
          access_key: { required },
          secret_key: { required },
        },
        gcp: {
          json_key: { required },
        },
        azure_storage: {
          access_key: { required },
          account_name: { required },
        },
      }
      return rawValidations[_.$get(this.formData, 'type')]
    },
  },
  watch: {
    credential: {
      async handler (newVal) {
        if (!_.isEmpty(newVal)) {
          this.$set(this.formData, 'type', this.getProviderExtraInfoByCredType[newVal.type]?.type || newVal.type)
          await this.$nextTick()
          this.formData = {
            ...this.formData,
            ..._.pick(newVal, ['name', 'path', 'raw']),
          }
        }
      },
      immediate: true,
      deep: true,
    },
    canSave (newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('is-valid', newVal)
    },
    isPathCustom (newVal) {
      if (_.isNull(newVal)) return
      if (!newVal) this.setPathToGenerated()
    },
    'formData.name' () {
      if (!this.$hasDataChanged('formData')) return
      if (!this.isPathCustom) this.setPathToGenerated()
      this.$v.formData.name.$touch()
    },
    'formData.path' () {
      if (!this.$hasDataChanged('formData')) return
      this.$v.formData.path.$touch()
    },
    'formData.type' (newVal) {
      if (!this.$hasDataChanged('formData')) return
      if (!this.isPathCustom) this.setPathToGenerated()
      this.setRaw(newVal)
      this.$v.formData.type.$touch()
      this.$emit('type-changed', newVal)
    },
    formData: {
      handler () {
        if (!this.$hasDataChanged('formData')) {
          this.setIsPathCustom(this.formData.path !== this.generatedPath)
          this.$v.$reset()
        }

        this.canSave = checksPass({
          noErrors: !this.$v.$anyError,
          allValid: !this.$v.$invalid,
        })
      },
      deep: true,
    },
  },
  async created () {
    const { credential } = this
    if (!_.isEmpty(credential)) {
      this.$set(this.formData, 'type', credential.type)
      await this.$nextTick()
      this.$set(this, 'formData', _.cloneDeep(_.pick(credential, ['name', 'type', 'path', 'raw'])))
    }
  },
  methods: {
    setIsPathCustom (value = true) {
      this.isPathCustom = Boolean(value)
    },
    setPathToGenerated () {
      this.$set(this.formData, 'path', this.generatedPath)
    },
    setRaw (type) {
      this.$set(this.formData, 'raw', _.cloneDeep(CREDENTIAL_DEFAULTS[type]))
    },
  },
}
</script>

<style type="scss" scoped>
.credential__raw-container {
  margin-top: 18px;
}

.credentials-list__item {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
</style>
